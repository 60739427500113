import React, { useState, useEffect, useContext, useRef } from "react";
import { DataContext } from "../../Helper/context";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import logo from "../../assets/images/logo/logo.png";
import { logoutAction } from "../../Helper/functions";
import { Languages } from "../../Helper/languages";

const Header = () => {
  const navigate = useNavigate();
  const [langOpen, setLangOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { user, setUser, language, setLanguage } = useContext(DataContext);
  const [scroll, setScroll] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setLangOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  //useeffect for window resize
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    logoutAction().then(() => {
      setUser(false);
      navigate("/");
    });
  };


  const handleLanguageClick = () => {
    setLangOpen(!langOpen);
  };

  return (
    <header className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div id="site-logo" className="clearfix">
                <div id="site-logo-inner">
                  <Link to="/" rel="home" className="main-logo">
                    <img
                      id="logo_header"
                      className="logo-dark"
                      src={logo}
                      alt="paintr"
                    />
                    <img
                      id="logo_header"
                      className="logo-light"
                      src={logo}
                      alt="paintr"
                    />
                  </Link>
                </div>
              </div>

              <div className="header-center">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                >
                  <ul id="menu-primary-menu" className="menu">
                    {!!user && (
                      <>
                        <li className="menu-item">
                          <Link to="/inspiration">
                            {Languages[language].inspiration}
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/create">{Languages[language].create}</Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/profile">
                            {Languages[language].profile}
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/gallery">
                            {Languages[language].gallery}
                          </Link>
                        </li>
                        {width < 1450 && (
                          <li className="menu-item">
                            <Link onClick={handleLogout}>
                              {Languages[language].logout}
                            </Link>
                          </li>
                        )}
                      </>
                    )}
                    {!user && (
                      <>
                        <li className="menu-item">
                          <Link to="/inspiration">
                            {Languages[language].inspiration}
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link to="/create">{Languages[language].create}</Link>
                        </li>
                        {width < 1450 && (
                          <>
                            <li className="menu-item">
                              <Link to="/signin">
                                {Languages[language].login}
                              </Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/signup">
                                {Languages[language].register}
                              </Link>
                            </li>
                          </>
                        )}
                      </>
                    )}
                    <li
                      ref={wrapperRef}
                      onClick={handleLanguageClick}
                      style={{ position: "relative" }}
                      className="menu-item"
                    >
                      <a style={{ cursor: "pointer" }}>Lang</a>
                      {langOpen && (
                        <div className="language_container">
                          <div
                            className="language_item"
                            onClick={() => {
                              setLanguage("en");
                              localStorage.setItem("language", "en");
                            }}
                          >
                            English
                          </div>
                          <div
                            className="language_item"
                            onClick={() => {
                              setLanguage("az");
                              localStorage.setItem("language", "az");
                            }}
                          >
                            Azerbaijani
                          </div>
                          <div
                            className="language_item"
                            onClick={() => {
                              setLanguage("fr");
                              localStorage.setItem("language", "fr");
                            }}
                          >
                            Français
                          </div>
                        </div>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="header-right">
                {!user && (
                  <>
                    {" "}
                    <Link to="/signin" className="tf-button discord">
                      <span>{Languages[language].login}</span>
                    </Link>
                    <Link
                      to="/signup"
                      className="tf-button connect"
                      data-toggle="modal"
                      data-target="#popup_bid"
                    >
                      <span>{Languages[language].register}</span>
                    </Link>
                  </>
                )}
                {!!user && (
                  <>
                    <Link
                      onClick={handleLogout}
                      className="tf-button connect"
                      data-toggle="modal"
                      data-target="#popup_bid"
                    >
                      <span>{Languages[language].logout}</span>
                    </Link>
                  </>
                )}

                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
