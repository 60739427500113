import React, {useState, useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {GetAllJobs, GetCurrentRate, loginAction} from "../Helper/functions";
import {useNavigate} from "react-router-dom";
import {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import data from '../assets/fake-data/data-collection'
import {DataContext} from "../Helper/context";


function Login(props) {
    const navigate = useNavigate();
    const {setUser} = useContext(DataContext);
    const [error, setError] = useState(null);
    const [loginCredentials, setLoginCredentials] = useState({
        email: '',
        password: ''
    });
    useEffect(() => {
        const timer = setTimeout(() => {
            setError(null);
        }, 5000);
        return () => clearTimeout(timer);
    }, [error]);

    const handleLogin = async (e) => {
        e.preventDefault();
        const {email, password} = loginCredentials;
        if(email === '' || password === '') return setError('Please fill in all fields')
        if(email.length < 3 || password.length < 3) return setError('Please fill in all fields')
      
        await loginAction("https://api.paintr.ai/login", email, password).then(res => {
            if (!res?.data) return;
            setUser(res?.data)
            localStorage.setItem('userCredentials', JSON.stringify(res?.data));
            Promise.all([
                GetCurrentRate('https://api.paintr.ai/rates'),
                GetAllJobs(res?.data)
            ])
                .then(() => navigate('/gallery'))
        }).catch(c => {
            setError(c?.response?.data?.error.message ?? 'Something went wrong')

        });
    }

    return (
        <div>

            <section className="bg-sign-in">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="content-left vertical-carousel">
                                <div className="content-slide">

                                    <Swiper
                                        className="collection-1"
                                        direction={"vertical"}
                                        spaceBetween={30}
                                        slidesPerView={3}

                                        loop={true}
                                        modules={[Autoplay]}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}
                                    >

                                        {
                                            data.slice(3, 7).map(idx => (
                                                <SwiperSlide key={idx.id}>
                                                    <div className="slider-item">
                                                        <div className="tf-product ">
                                                            <div className="image">
                                                                <img src={idx.img} alt="Binabox"/>
                                                            </div>
                                                            <h6 className="name"><Link
                                                                to="/item-details">{idx.title}</Link></h6>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }

                                    </Swiper>
                                    <Swiper
                                        className="collection-1"
                                        direction={"vertical"}
                                        spaceBetween={30}
                                        slidesPerView={3}
                                        loop={true}
                                        modules={[Autoplay]}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}

                                    >

                                        {
                                            data.slice(3, 9).map(idx => (
                                                <SwiperSlide key={idx.id}>
                                                    <div className="slider-item">
                                                        <div className="tf-product ">
                                                            <div className="image">
                                                                <img src={idx.img} alt="Binabox"/>
                                                            </div>
                                                            <h6 className="name"><Link
                                                                to="/item-details">{idx.title}</Link></h6>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }

                                    </Swiper>
                                    <Swiper
                                        className="collection-1"
                                        direction={"vertical"}
                                        spaceBetween={30}
                                        slidesPerView={3}

                                        loop={true}
                                        modules={[Autoplay]}
                                        autoplay={{
                                            delay: 5000,
                                            disableOnInteraction: false,
                                        }}
                                    >
                                        {
                                            data.slice(3, 13).map(idx => (
                                                <SwiperSlide key={idx.id}>
                                                    <div className="slider-item">
                                                        <div className="tf-product ">
                                                            <div className="image">
                                                                <img src={idx.img} alt="Binabox"/>
                                                            </div>
                                                            <h6 className="name"><Link
                                                                to="/item-details">{idx.title}</Link></h6>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }

                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="sign-in-form">
                                <h2>SIGN IN</h2>
                                {error === null && <p>Welcome back! Please enter your details</p>}
                                {error !== null && <p style={{color:'red',fontWeight:'bold'}}>{error}</p>}
                                <form action="#" id="contactform">
                                    <fieldset><input
                                        onChange={(e) => setLoginCredentials({
                                            ...loginCredentials,
                                            email: e.target.value
                                        })}
                                        id="name" name="name" tabIndex="1"  type="text"
                                        placeholder="Username or Email"/></fieldset>
                                    <fieldset><input
                                        onChange={(e) => setLoginCredentials({
                                            ...loginCredentials,
                                            password: e.target.value
                                        })}
                                        id="showpassword" name="password" tabIndex="2"
                                        type="password" placeholder="Password" required={true}/></fieldset>
                                    <div className="forgot-pass-wrap">
                                        <label>
                                            <input type="checkbox"/>
                                            <span className="btn-checkbox"></span>
                                            Remember me
                                        </label>

                                    </div>
                                    <button className="tf-button submit" type="submit"
                                            onClick={(e) => handleLogin(e)}
                                    >SIGN IN
                                    </button>
                                </form>
                                <div className="choose-sign">
                                    Do you have account? <Link to="/signup">Sign up</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
}

export default Login;