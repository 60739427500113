import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';
import img from '../assets/images/item-details.jpg'
import Footer2 from "../components/footer/Footer2";


function Inspiration(props) {

    return (
        <div className='page-collection'>

            <PageTitle title='Inspiration' />

            <section className=" tf-item-detail ">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-image">
                                <img  src={`https://api.paintr.ai/image/1674553626266`} alt="PaintR" />

                            </div>
                        </div>
                        <div style={{alignSelf:'center'}} className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-inner">
                                <h2 className="title">Seed : 128418612 </h2>
                                <p className="des">oil painting with heavy impasto of a pirate ship and its captain, cosmic horror painting, elegant intricate artstation concept art by craig mullins detailed
                                </p>
                                <div className="infor-item-wrap">
                                    <div className="infor-item-box">
                                        <div className="category">Artist</div>
                                        <h4 className="name">Artist1</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Size:</div>
                                        <h4 className="name">512 X 832</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Steps:</div>
                                        <h4 className="name">20</h4>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className=" tf-item-detail ">
                <div className="tf-container">
                    <div className="row" style={{flexDirection:'row-reverse'}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-image">
                                <img  src={`https://api.paintr.ai/image/1674556649916`} alt="PaintR" />

                            </div>
                        </div>
                        <div style={{alignSelf:'center'}} className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-inner">
                                <h2 className="title">Seed : 2659128104 </h2>
                                <p className="des">portrait of a rugged 19th century man with mutton chops in a jacket, victorian, concept art, detailed face, fantasy, close up face, highly detailed, cinematic lighting, digital art painting by greg rutkowski
                                </p>
                                <div className="infor-item-wrap">
                                    <div className="infor-item-box">
                                        <div className="category">Artist</div>
                                        <h4 className="name">Artist1</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Size:</div>
                                        <h4 className="name">512 X 768</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Steps:</div>
                                        <h4 className="name">35</h4>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className=" tf-item-detail ">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-image">
                                <img  src={`https://api.paintr.ai/image/1674555082583`} alt="PaintR" />

                            </div>
                        </div>
                        <div style={{alignSelf:'center'}} className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-inner">
                                <h2 className="title">Seed : 851675294 </h2>
                                <p className="des">photo of 8k ultra realistic harbour, port, boats, sunset, beautiful light, golden hour, full of colour, cinematic lighting, battered, trending on artstation, 4k, hyperrealistic, focused, extreme details,unreal engine 5, cinematic, masterpiece, art by studio ghibli
                                </p>
                                <div className="infor-item-wrap">
                                    <div className="infor-item-box">
                                        <div className="category">Artist</div>
                                        <h4 className="name">Artist3</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Size:</div>
                                        <h4 className="name">512 X 768</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Steps:</div>
                                        <h4 className="name">35</h4>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className=" tf-item-detail ">
                <div className="tf-container">
                    <div className="row" style={{flexDirection:'row-reverse'}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-image">
                                <img  src={`https://api.paintr.ai/image/1674553872116`} alt="PaintR" />

                            </div>
                        </div>
                        <div style={{alignSelf:'center'}} className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-inner">
                                <h2 className="title">Seed : 3932594161 </h2>
                                <p className="des">highly detailed portrait of pierce brisnan as old sailor, by Dustin Nguyen, Akihiko Yoshida, Greg Tocchini, Greg Rutkowski, Cliff Chiang, 4k resolution, Dishonored inspired, bravely default inspired, vibrant but dreary red, black and white color scheme! ! ! , epic extreme long shot, dark mood and strong backlighting, volumetric lights, smoke volutes, artstation HQ, unreal engine, octane renderer, HQ, 8K
                                </p>
                                <div className="infor-item-wrap">
                                    <div className="infor-item-box">
                                        <div className="category">Artist</div>
                                        <h4 className="name">Artist2</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Size:</div>
                                        <h4 className="name">1024 X 1024</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">Steps:</div>
                                        <h4 className="name">30</h4>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer2 />

        </div>
    );
}

export default Inspiration;