import axios from "axios";

export const loginAction = async (URL, email, pass) => {
    return await axios.post(URL, {
        email: email,
        password: pass
    });
}

export const registerAction = async (URL, email, pass) => {
    return await axios.post(URL, {
        email: email,
        password: pass
    })
}

export const logoutAction = async () => {
    localStorage.removeItem("userCredentials")
    localStorage.removeItem("Rates")
    localStorage.removeItem("jobs")
}

// `https://api.paintr.ai/jobs`
export const GetAllJobs = async (user, merge) => {
    try {
        const data = (await axios.get(`https://api.paintr.ai/jobs`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })).data
        if(typeof data !== 'object' || data === null) throw Error("Data is not an object")
        if (!!merge) {
            const mergeDatas = [...data,...merge]
            localStorage.setItem('jobs', JSON.stringify(mergeDatas))
            return mergeDatas
        }
        localStorage.setItem('jobs', JSON.stringify(data))
        return data
    } catch (error) {
        console.error(error)
        throw error
    }

}

export const updateProfile = (URL, dataObject, user) => {
    return axios.patch(URL, dataObject, {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    })
}

export const GetCurrentRate = async (URL) => {
    axios.get(URL).then(res => {
        if (!res?.data) return null
        localStorage.setItem('Rates', JSON.stringify(res.data))
    })
}

//always need at least one empty job
export const EmptyJobCheck = (user) => {
    const createEmptyJob = async () => {
      return await axios
        .patch(
          `https://api.paintr.ai/job`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          //merge all jobs with new job and save to localstorage
          GetAllJobs(user, [res.data]);
          return res.data;
        })
        .catch((error) => {});
    };
  
    let localStorageJobs = localStorage.getItem("jobs");
    if (localStorageJobs === null) return createEmptyJob();
    let emptyJob = JSON.parse(localStorageJobs).find(
      (job) => job.status === "init"
    );
    if (emptyJob === undefined) {
      return createEmptyJob();
    } else {
      return emptyJob;
    }
  };

export const DeleteJob = async (URL, user, id) => {
    await axios.delete(`${URL}/${id}`, {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    })

}
export const removeEmptyJobFromLocalStorage = () => {
    let jobs = JSON.parse(localStorage.getItem("jobs"))
    let filteredJobs = jobs.filter(job => job.status !== 'init')
    localStorage.setItem("jobs", JSON.stringify(filteredJobs))
}

