import React,{useContext} from 'react';
import Button from '../button/Button';
import Button02 from '../button/Button02';
import { Languages } from '../../Helper/languages';
import { DataContext } from '../../Helper/context';
import './styles.scss';

function Banner02(props) {
    const {language} = useContext(DataContext);
    return (
        <section className="tf-slider home2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            
                            <div className="tf-slider-item style-2">
                                <div className="content-inner wow fadeInUp" data-wow-delay="0.4s">
                                    <h1 className="heading">{Languages[language].home_page_first_slider_top_text}
                                    </h1>
                                    <p className="sub-heading wow fadeInUp" data-wow-delay="0.6s">
                                        {Languages[language].home_page_first_slider_bottom_text}
                                    </p>
                                    <div className="btn-slider wow fadeInUp" data-wow-delay="0.8s">
                                        <Button title={Languages[language].create} path='create' />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    );
}

export default Banner02;