import Page404 from "./404";
import Gallery from "./Gallery";
import Faq01 from "./Faq01";
import Home02 from "./Home02";
import Login from "./Login";
import SignUp from "./SignUp";
import Profile from "./Profile";
import Create from "./Create";
import Thanks from "./Thanks";
import Terms from "./Terms";
import View from "./View";
import Inspiration from "./Inspiration";


const routes = [
    {path: '/', component: <Home02/>},
    {path: '/inspiration', component: <Inspiration/>},
    {path: '/profile', component: <Profile/>},
    // {path: '/home-v2', component: <Home01/>},
    // {path: '/home-v3', component: <Home03/>},
    // {path: '/about-v1', component: <About01/>},
    // {path: '/about-v2', component: <About02/>},
    // {path: '/roadmap-v1', component: <RoadMap01/>},
    // {path: '/roadmap-v2', component: <RoadMap02/>},
    // {path: '/roadmap-v3', component: <RoadMap03/>},
    {path: '/signin', component: <Login/>},
    {path: '/signup', component: <SignUp/>},
    {path: '/faq-v1', component: <Faq01/>},
    // {path: '/faq-v2', component: <Faq02/>},
    // {path: '/our-team', component: <OurTeam/>},
    {path: '/gallery', component: <Gallery/>},
    // {path: '/testimonial', component: <Testimonials/>},
    // {path: '/item-details', component: <ItemDetails/>},
    {path: '/view', component: <View/>},
    // {path: '/comming-soon', component: <CommingSoon/>},
    {path: '/create', component: <Create/>},
    {path: '/thanks', component: <Thanks/>},
    {path: '/terms', component: <Terms/>},
    {path: '/page-404', component: <Page404/>},
    // {path: '/blog', component: <Blog/>},
    // {path: '/blog-details', component: <BlogDetails/>},
    // {path: '/contact', component: <Contact/>},


]

export default routes;