import React, { useState, useEffect, useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { DataContext } from "../Helper/context";
import {
  EmptyJobCheck,
  registerAction,
  loginAction,
  GetCurrentRate,
} from "../Helper/functions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import data from "../assets/fake-data/data-collection";

function SignUp(props) {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [notRobot, setNotRobot] = useState(false);
  const [regsiterCredentials, setRegisterCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const { setUser } = useContext(DataContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [error]);

  const handleRegister = async (e) => {
    e.preventDefault();
    const { email, password, confirmPassword } = regsiterCredentials;
    if (password !== confirmPassword) {
      setError("Password and confirm password do not match");
      return;
    }
    if (email === "" || password === "" || confirmPassword === "") {
      setError("Please fill all fields");
      return;
    }
    if (email.length < 6 || password.length < 5) {
      setError("Email or password is too short");
      return;
    }
    if (!notRobot) {
      setError("Please confirm you are not a robot");
      return;
    }

    await registerAction("https://api.paintr.ai/register", email, password)
      .then((res) => {
        EmptyJobCheck(res.data);
      })
      .then(() => {
        const handleLogin = async () => {
          const { email, password } = regsiterCredentials;
          await loginAction("https://api.paintr.ai/login", email, password)
            .then((res) => {
              if (!res?.data) return;
              setUser(res?.data);
              localStorage.setItem(
                "userCredentials",
                JSON.stringify(res?.data)
              );
              Promise.all([GetCurrentRate("https://api.paintr.ai/rates")]).then(
                () => navigate("/profile")
              );
            })
            .catch((c) => {
              setError(
                c?.response?.data?.error.message ?? "Something went wrong"
              );
            });
        };
        handleLogin();
        // navigate('/signin');
      })
      .catch((c) => {
        setError(c?.response?.data?.error?.detail ?? "Something went wrong");
      });
  };


  function onChange(value) {
    setNotRobot(value);
  }

  return (
    <div>
      <section className="bg-sign-in">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="content-left vertical-carousel">
                <div className="content-slide">
                  <div className="content-slide">
                    <Swiper
                      className="collection-1"
                      direction={"vertical"}
                      spaceBetween={30}
                      slidesPerView={3}
                      loop={true}
                      modules={[Autoplay]}
                      autoplay={{
                        delay: 4500,
                        disableOnInteraction: false,
                      }}
                    >
                      {data.slice(0, 7).map((idx) => (
                        <SwiperSlide key={idx.id}>
                          <div className="slider-item">
                            <div className="tf-product ">
                              <div className="image">
                                <img src={idx.img} alt="Binabox" />
                              </div>
                              <h6 className="name">
                                <Link to="/item-details">{idx.title}</Link>
                              </h6>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <Swiper
                      className="collection-1"
                      direction={"vertical"}
                      spaceBetween={30}
                      slidesPerView={3}
                      loop={true}
                      modules={[Autoplay]}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                    >
                      {data.slice(3, 9).map((idx) => (
                        <SwiperSlide key={idx.id}>
                          <div className="slider-item">
                            <div className="tf-product ">
                              <div className="image">
                                <img src={idx.img} alt="Binabox" />
                              </div>
                              <h6 className="name">
                                <Link to="/item-details">{idx.title}</Link>
                              </h6>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <Swiper
                      className="collection-1"
                      direction={"vertical"}
                      spaceBetween={30}
                      slidesPerView={3}
                      loop={true}
                      modules={[Autoplay]}
                      autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                      }}
                    >
                      {data.slice(3, 13).map((idx) => (
                        <SwiperSlide key={idx.id}>
                          <div className="slider-item">
                            <div className="tf-product ">
                              <div className="image">
                                <img src={idx.img} alt="Binabox" />
                              </div>
                              <h6 className="name">
                                <Link to="/item-details">{idx.title}</Link>
                              </h6>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sign-in-form style2">
                <h2>SIGN UP</h2>
                {error === null && (
                  <p>Welcome back! Please enter your details</p>
                )}
                {error !== null && (
                  <p style={{ color: "red", fontWeight: "bold" }}>{error}</p>
                )}
                <form action="#" id="contactform">
                  <fieldset>
                    <input
                      onChange={(e) =>
                        setRegisterCredentials({
                          ...regsiterCredentials,
                          email: e.target.value,
                        })
                      }
                      name="email"
                      tabIndex="2"
                
                      type="email"
                      placeholder="Your email"
                      required={true}
                    />
                  </fieldset>
                  <fieldset>
                    <input
                      onChange={(e) =>
                        setRegisterCredentials({
                          ...regsiterCredentials,
                          password: e.target.value,
                        })
                      }
                      id="showpassword"
                      name="password"
                      tabIndex="2"
                    
                      type="password"
                      placeholder="Set your password"
                      required={true}
                    />
                  </fieldset>
                  <fieldset>
                    <input
                      onChange={(e) =>
                        setRegisterCredentials({
                          ...regsiterCredentials,
                          confirmPassword: e.target.value,
                        })
                      }
                      id="showpassword"
                      name="password"
                      tabIndex="2"
                   
                      type="password"
                      placeholder="Confirm the password"
                
                    />
                  </fieldset>
                  <button
                    className="tf-button submit"
                    type="submit"
                    onClick={(e) => handleRegister(e)}
                  >
                    SIGN UP
                  </button>
                  <div style={{display:'flex', justifyContent:'center', marginBottom:'8px'}}>
                     <ReCAPTCHA
                    sitekey="6LcNkZ4lAAAAAIYx_5CF-pWQ-s6TYfbzfOuOtxmo"
                    onChange={onChange}
                  />
                  
                  </div>
                 
                </form>
                <div className="choose-sign">
                  Already have an account? <Link to="/signin">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignUp;
