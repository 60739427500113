import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import './App.scss'
import ScrollToTop from "./ScrollToTop";
import DataContextProvider from "./Helper/context";
import LeftBottomWrapper from './components/layouts/LeftBottomWrapper'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <DataContextProvider>
            <BrowserRouter>
                <ScrollToTop/>
                <LeftBottomWrapper/>
                <App/>
            </BrowserRouter>
        </DataContextProvider>
    </React.Fragment>
);

