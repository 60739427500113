import React from 'react';
import dataCollection from '../assets/fake-data/data-collection';
import About2 from '../components/about/About2';
import Banner02 from '../components/banner/Banner02';
import Collection01 from '../components/collection/Collection01';
import Faqs2 from '../components/faqs/Faqs2';
import Footer2 from '../components/footer/Footer2';




function Home02(props) {

    return (
        <div className='home-2'>
            
            <Banner02 />
            <About2 />
            <Collection01 data={dataCollection} />
            <Faqs2 />
            <Footer2 />
            
        </div>
    );
}

export default Home02;