import React,{useContext, useState} from 'react'
import { DataContext } from '../../Helper/context'
import { Languages } from '../../Helper/languages'
import { EmptyJobCheck } from '../../Helper/functions'

export default function Invoice({invoice_data}) {
    const {user,language} = useContext(DataContext)
  
    
  return (
    <div
    className='invoice'
    style={{
        display: 'flex',
        flexDirection: 'column',
    }}>
         <span>{Languages[language].Order_ID}: {invoice_data?.emptyJob?.id}</span> 
        <span>{Languages[language].Art_Name}: {invoice_data.prompt}</span>
        <span>{Languages[language].steps}: {invoice_data.steps}</span>
        <span>{Languages[language].dimensions} (px): {invoice_data.width} x {invoice_data.height} </span> 
        <span>{Languages[language].price}: {invoice_data.priceData.price} {invoice_data.priceData.currency}</span>
        <span>{Languages[language].Payment_Status}: {Languages[language].unpaid}</span>
    </div>
  )
}
