import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';
import { Languages } from '../Helper/languages';
import {useNavigate} from "react-router-dom";
import {DataContext} from "../Helper/context";
import { Link } from 'react-router-dom';
import {saveAs} from 'file-saver'
import Footer2 from "../components/footer/Footer2";
import {DeleteJob, GetAllJobs} from "../Helper/functions";

function View(props) {
    const navigate = useNavigate();
    const {user, language} = React.useContext(DataContext)
    const url = window.location.href;
    const imageId = url.split('?').pop();
    const imageData = localStorage.getItem('jobs');
    const jobs = imageData !== undefined && JSON.parse(imageData);
    const findJobById =( jobs && jobs.find(job => job.id === imageId) )|| [];

    if (!user) {
        navigate('/login');
    }
    if (!findJobById?.id) {
        navigate('/gallery')
    }

    const {config, payment, id} = findJobById;
    const seed = findJobById?.meta?.info?.seed ?? "Random";

    let {seed:seed2, artist, size, steps, price,download,delete:delete2,create} = Languages[language];

    return (
        <div className='page-collection'>

            <PageTitle title={Languages[language].item_details} />

            <section className=" tf-item-detail ">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-image">
                                <img  src={`https://api.paintr.ai/image/${imageId}`} alt="PaintR" />

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="tf-item-detail-inner">
                                <h2 className="title">{seed2} </h2>
                                <p className="des">{config?.prompt}
                                </p>
                                <div className="infor-item-wrap">
                                    <div className="infor-item-box">
                                        <div className="category">{artist}</div>
                                        <h4 className="name">{config?.sampler_index}</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">{size}:</div>
                                        <h4 className="name">{config?.width} X {config?.height}</h4>
                                    </div>
                                    <div className="infor-item-box">
                                        <div className="category">{steps}:</div>
                                        <h4 className="name">{config?.steps}</h4>
                                    </div>



                                </div>
                                <div className="price">
                                    <span className="heading">{price}:</span>
                                    <span>{Math.round(payment?.amount)} {payment?.currency}</span>
                                </div>
                                <div className="group-btn">
                                    <button onClick={()=>{
                                        let randomNumber = Math.floor(Math.random() * 100000)
                                        saveAs(`https://api.paintr.ai/image/${imageId}`, `image${randomNumber}.jpg`)
                                    }} className="tf-button opensea"> {download}</button>
                                    <div className="group-2">
                                        <button onClick={()=>{
                                            DeleteJob("https://api.paintr.ai/job", user, id).then((res) => {
                                                GetAllJobs(user).then((res) => {
                                                    navigate('/gallery')
                                                })
                                            })
                                        }} className="tf-button style-2 "> {delete2}</button>
                                        <Link to="/create" className="tf-button style-2 twitter"> {create}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer2 />

        </div>
    );
}

export default View;