import img1 from '../images/product/product1.jpg'
import img2 from '../images/product/product2.jpg'
import img3 from '../images/product/product10.jpg'
import img4 from '../images/product/product5.jpg'
import img5 from '../images/product/product9.jpg'
import img6 from '../images/product/product8.jpg'
import img7 from '../images/product/product11.jpg'
import img8 from '../images/product/product7.jpg'
import img9 from '../images/product/product6.jpg'
import img10 from '../images/product/product8.jpg'
import img11 from '../images/product/product4.jpg'
import img12 from '../images/product/product3.jpg'
import img13 from '../images/product/product12.jpg'
import img14 from '../images/product/product13.jpg'
import img15 from '../images/product/product14.jpg'
import img16 from '../images/product/product15.jpg'
import img17 from '../images/product/product16.jpg'
import img18 from '../images/product/product17.jpg'
import img19 from '../images/product/product18.jpg'
import img20 from '../images/product/product19.jpg'
import img21 from '../images/product/product21.jpg'
import img22 from '../images/product/product22.jpg'
import img23 from '../images/product/product23.jpg'
import img24 from '../images/product/product24.jpg'
import img25 from '../images/product/product25.jpg'
import img26 from '../images/product/product20.jpg'


const dataCollection = [
    {
        id: 1,
        img: `https://api.paintr.ai/image/1674557980147`,
        title: 'Dementor levitating',
    },
    {
        id: 2,
        img: `https://api.paintr.ai/image/1674557933305`,
        title: 'Random',
    },
    {
        id: 3,
        img: `https://api.paintr.ai/image/1675265641876`,
        title: 'oil painting with ...',
    },
    {
        id: 4,
        img: `https://api.paintr.ai/image/1674556649916`,
        title: '19th century man',
    },
    {
        id: 5,
        img: `https://api.paintr.ai/image/1674555082583`,
        title: 'Realistic harbour',
    },
    {
        id: 6,
        img: `https://api.paintr.ai/image/1674553626266`,
        title: 'Oil painting pirate ship',
    },
    {
        id: 7,
        img: `https://api.paintr.ai/image/1674553157748`,
        title: 'Portrait art of b..',
    },
    {
        id: 8,
        img: `https://api.paintr.ai/image/1674553872116`,
        title: 'Portrait of ...',
    },



   
]

export default dataCollection;