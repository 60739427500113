import React from "react";
import { qnas } from "../components/pop-thingy/helpers/getQnaTranslations";

export const Languages = {
  en: {
    //buttons
    high_quality: "High Quality",
    login: "Login",
    register: "Register",
    logout: "Sign out",
    profile: "Profile",
    gallery: "Gallery",
    create: "Create",
    inspiration: "Inspiration",
    make_art: "Make Art",
    my_gallery: "My Gallery",
    generate_picture: "Generate Picture",
    submit: "Submit",
    refresh: "Refresh",
    download: "Download",
    go_to_customize: "Go to Customize",
    save: "Save",
    delete: "Delete",
    go_to_create: "Go to Create",
    terms_and_conditions: "Terms and Conditions",
    privacy_policy: "Privacy Policy",
    dimensions: "Dimensions",
    Order_ID: "Order ID",
    Art_Name: "Art Name",
    Payment_Status: "Payment Status",
    unpaid: "Unpaid",

    //profile
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    password: "Password",
    confirm_password: "Confirm Password",
    phone: "Phone",
    address: "Address",
    city: "City",
    state: "State",
    zip: "Zip",
    country: "Country",
    county: "County",


    qna: qnas, 

    pop_refund_title: "Which image made you make this decision?",
    pop_refund_button: "Accept & Send",
    pop_refund_button_disabled: "Select atleast one!",

    pop_redirect_close: "Alright. Hope you can solve it.",
    pop_redirect_redirecting: "Redirecting...",
    pop_title: "Not feeling satisfied?",
    pop_text: "Let us solve your problem.",
    pop_button_text: "Click here!",
    pop_qna_close_text: "Close",

    //inspiration
    steps: "Steps",
    seed: "Seed",
    width: "Width",
    height: "Height",
    artist: "Artist",
    template: "Template",
    input: "Input",
    price: "Price",
    create_amazing_pictures: "Create Amazing Pictures",

    //footer action div
    footer_action_div_top_text: "Create Your New Art",
    footer_action_div_bottom_text: "Generate something amazing",
    //create page
    positive_top_text: "Tell us about your masterpiece",
    positive_bottom_text: (
      <>
        Enter some text for the artist to create your image, it works best if
        you think of your ideas as captions for the result you would like.
        <br></br>
      </>
    ),
    avoid_top_text: "Is there something you want to avoid",
    avoid_bottom_text: (
      <>
        You can add things that you don't want to see in your image here. as
        above it's best to use captions of the items to avoid.
      </>
    ),
    artist_top_text: "Your artist",
    artist_bottom_text: (
      <>
        There are currently 3 artists to choose from. These artists will make
        different decisions each step of the way to create your magic. you can
        see some examples above; for beginners we suggest artist 1
      </>
    ),
    size_top_text: "What size would you like your works",
    size_bottom_text: "You can select from 64px to 2048px for height or width.",
    high_top_text: "Going BIG?",
    high_bottom_text:
      "The artists work best around 512px and if going past this point it should be done in a single direction or with the assistance of this high-quality setting that will split the work into 512x512 jobs and merge them.",
    high_input_label: "High Quality",
    steps_top_text: "Steps",
    steps_bottom_text:
      "Each step allows the artist to improve your image, around 20 steps will get you a basic image, 50 or so would be better for higher quality.",
    steps_input_label: "Sampling steps",
    artist_freedom_top_text: "Artist Freedom",
    artist_freedom_bottom_text: (
      <>
        Here you can choose the weight of your input to the piece. A basic
        setting of around 7 yields results that both represent your ideas and
        have some freedom of expression. 1 will only contain a hint of your
        input and 30 will most likly over bake the ideas.
        <br></br> Suggested: 5-15
      </>
    ),
    artist_freedom_input_label: "CFG scale",
    //create page/quality
    starting_number: "Starting number",
    starting_number_bottom_text: "Optional",
    draft_quality: "Draft",
    draft_quality_bottom_text: "Most cost-effective",
    basic_quality: "Basic",
    basic_quality_bottom_text: "More refined",
    standard_quality: "Standard",
    standard_quality_bottom_text: "Double the size",
    premium_quality: "Premium",
    premium_quality_bottom_text: "More refined",
    supreme_quality: "Supreme",
    supreme_quality_bottom_text: "Huge",
    custom: "Custom",
    custom_bottom_text: "Go crazy",
    //home page
    home_page_first_slider_top_text: "Compose your picture with AI",
    home_page_first_slider_bottom_text:
      "We are the best way to generate amazing images",
    home_page_second_slider_top_text: "Create pictures almost instantly",
    home_page_second_slider_middle_text:
      "you supply the concepts for the art and have access to additional advanced options if you want to go crazy",
    home_page_second_slider_bottom_text:
      "You can download and have full usage rights to your artwork, along with all the information needed to recreate or remix your creation. ",
    idea: "Idea",
    write: "Write",
    wait: "Wait",
    art: "Art",
    join: "Join",
    join_top_text: "Join and create amazing images",
    become: (
      <>
        Become a Artfactory <br></br> User Now{" "}
      </>
    ),
    register_text: "Register an account with us and get amazing pictures",
    go_to_create_page: "Go to Create Page",
    go_to_create_page_text: "Go to create page and create your own images",
    write_your_magic: "Write your magic",
    write_your_magic_text: "Write your magic and the machine will do the rest",
    wait_for_the_result: "Wait for the result",
    wait_for_the_result_text: "Wait for the results and enjoy your new images",

    FAQ_short_title: "FAQ",
    FAQ_title: "Frequently Asked Questions",
    FAQ: [
      {
        id: 1,
        title: "WHAT IS AN ARTIST?",
        content: (
          <>
            <p>Each artist is an image generation system.</p>
            <p>It combines previous ideas including:</p>
            <ul>
              <li>
                ● Perception Engines which uses image augmentation and
                iteratively optimises images against an ensemble of classifiers
              </li>
              <br></br>
              <li>
                ● CLIP guided GAN imagery from Ryan Murdoch and Katherine
                Crowson as well as modifictions such as CLIPDraw from Kevin
                Frans
              </li>
              <br></br>
              <li>
                ● Useful ways of navigating latent space from Sampling
                Generative Networks
              </li>
              <br></br>
            </ul>
          </>
        ),
        show: "true",
      },
      {
        id: 2,
        title: "WHY DOES IT COST MORE FOR LARGER IMAGES?",
        content: (
          <>
            <p>
              Every image is generated frame by frame and this consumes
              considerable resources.
            </p>
            <ul>
              <li>● Draft takes only a few seconds</li>
              <li>● Supreme can take 30 minutes</li>
              <br></br>
            </ul>
            <p>
              During this time we have the latest neural network hardware
              totally consumed by your artwork.
            </p>
            <p>
              We are able to scale by purchasing more hardware and process more
              in parallel.
            </p>
          </>
        ),
      },
      {
        id: 3,
        title: "WHY CANT I HAVE A BIGGER IMAGE?",
        content:
          "We are working on a solution to make even larger images, right now we are constrained by the amount of memory we can have in a single artist. We hope to add the addition of super-sampling to increase the size considerably.",
      },
    ],

    //unique ones

    home_data1: "SUPPLY THE IDEA",
    home_data2:
      "You supply the concepts for the art and have access to additional advanced options if you want to go crazy",
    home_data3: "Pay for the art",
    home_data4:
      "You will be able to pay for the art using the payment options provided.",
    home_data5: "THE ARTIST GETS TO WORK…",
    home_data6:
      "You will get the results of the artist’s work under the gallery page.",
    home_data7: "REVEL IN YOUR CREATION",
    home_data8:
      "You can download and have full usage rights to your artwork, along with all the information needed to recreate or remix your creation.",
    home_data9: "Generated Pictures",
    home_data10: (
      <>
        You supply the concepts for the art and have access to additional
        advanced options if you want to go crazy.
        <br />
        Perception Engines are able to construct representational physical
        objects and are powered primarily by computational perception.
        <br />
        VQGAN+CLIP is a neural network architecture that builds upon the
        revolutionary CLIP architecture published by OpenAI in January
        2021.Latent space can be sampled to generate observable data values.{" "}
        <br />
        Learned latent representations often also allow semantic operations with
        vector space arithmetic.
      </>
    ),
    hot_collection: "Hot Collection",
    quality: "Quality",
    settings: "Settings",
    name_email_phone: "Name, Email, Phone",
    location: "Location",
    size: "Size",
    item_details: "Item Details",
    home: "Home",
  },
  //France
  fr: {
    //buttons
    high_quality: "Haute qualité",
    login: "Connexion",
    register: "S'inscrire",
    logout: "Déconnexion",
    profile: "Profil",
    gallery: "Galerie",
    create: "Créer",
    inspiration: "Inspiration",
    make_art: "Faire de l'art",
    my_gallery: "Ma galerie",
    generate_picture: "Générer une image",
    submit: "Soumettre",
    refresh: "Rafraîchir",
    download: "Télécharger",
    go_to_customize: "Aller à Personnaliser",
    save: "Économiser",
    delete: "Effacer",
    go_to_create: "Allez dans Créer",
    terms_and_conditions: "Termes et conditions",
    privacy_policy: "Politique de confidentialité",

    //profile
    first_name: "Prénom",
    last_name: "Nom de famille",
    email: "E-mail",
    password: "Passe",
    confirm_password: "Confirmez le mot de passe",
    phone: "Téléphoner",
    address: "Adresse",
    city: "Ville",
    state: "Etat",
    zip: "Zip ",
    country: "Pays",
    county: "Comté",

    pop_title: "Vous ne vous sentez pas satisfait(e) ?",
    pop_text: "Laissez-nous résoudre votre problème.",
    pop_button_text: "Cliquez ici !",

    pop_qna_close_text: "Fermer",
    pop_something_went_wrong: "Oups, quelque chose s'est mal passé!",
    qna: {
        base: {
            question: "Qu'est-il arrivé ?",
            answers: {
                speed: {
                    text: "Vitesse",
                    redirect: "speed_whats_wrong"
                },
                payment: {
                    text: "Paiement"
                },
                quality: {
                    text: "Qualité"
                }
            }
        },

        speed_whats_wrong: {
            question: "Qu'est-ce qui ne va pas avec la vitesse ?",
            answers: {
                speed_too_slow: {
                    text: "Trop lent",
                },
                speed_not_loading: {
                    text: "Bloqué à la fin.",
                },
                speed_stuck: {
                    text: "Ne fonctionne pas.",
                },
            },
        },

        payment_whats_wrong: {
            question: "Qu'est-ce qui ne va pas avec le paiement?",
            answers: {
                payment_still_pending: {
                    text: "Déjà payé, mais ça indique toujours en attente...",
                },
                payment_charged_twice: {
                    text: "J'ai été débité deux fois.",
                },
            }
        },
        payment_still_pending: {
            question: "Essayez de le générer à nouveau?",
            answers: {
                payment_still_pending_regen: {
                    text: "Oui.",
                },
                payment_still_pending_refund: {
                    text: "Non, je veux un remboursement.",
                },
            },
        },

        quality_whats_wrong: {
            question: "Qu'est-ce qui ne va pas avec la qualité?",
            answers: {
                quality_bad: {
                    text: "La qualité de l'image est mauvaise.",
                },
                payment_charged_twice: {
                    text: "L'image n'est pas entièrement générée.",
                },
            },
        },
        quality_bad: {
            question: "Essayez de le générer à nouveau?",
            answers: {
                quality_bad_regen: {
                    text: "Oui.",
                },
                quality_bad_refund: {
                    text: "Non, je veux un remboursement.",
                },
            },
        },

        refund: {
            question: "Désolé d'apprendre que cela n'a pas fonctionné.",
            answers: {
                refund_refund: {
                    text: "Je souhaite un remboursement.",
                },
                refund_free_token: {
                    text: "Je souhaite un jeton gratuit."
                }
            }
        }
    },

    pop_refund_title: "Quelle image vous a fait prendre cette décision?",
    pop_refund_button: "Accepter et Envoyer",
    pop_refund_button_disabled: "Sélectionnez au moins un!",

    pop_redirect_close: "D'accord. J'espère que vous pourrez le résoudre.",
    pop_redirect_redirecting: "Redirection en cours...",

    //inspiration
    steps: "Pas",
    seed: "Planter",
    width: "Largeur",
    height: "Hauteur",
    artist: "Artiste",
    template: "Modèle",
    input: "Saisir",
    price: "Prix",
    create_amazing_pictures: "Créez des images étonnantes",
    dimensions: "Dimensions",
    Order_ID: "ID de commande",
    Art_Name: "Nom de l'art",
    Payment_Status: "Statut de paiement",
    unpaid: "Non payé",

    idea: "Idée",
    write: "Écrire",
    wait: "Attendre",
    art: "Art",
    //footer action div
    footer_action_div_top_text: "Créez votre nouvel art",
    footer_action_div_bottom_text: "Générez quelque chose d'incroyable",
    //create page
    positive_top_text: "Parlez-nous de votre chef-d'œuvre",
    positive_bottom_text: (
      <>
        Entrez du texte pour que l'artiste crée votre image, cela fonctionne
        mieux si vous considérez vos idées comme des légendes pour le résultat
        que vous souhaitez.<br></br>
      </>
    ),
    avoid_top_text: "Y a-t-il quelque chose que vous voulez éviter",
    avoid_bottom_text: (
      <>
        Vous pouvez ajouter ici des choses que vous ne voulez pas voir dans
        votre image. comme ci-dessus, il est préférable d'utiliser les légendes
        des éléments à éviter.
      </>
    ),
    artist_top_text: "Votre artiste",
    artist_bottom_text: (
      <>
        Il y a actuellement 3 artistes parmi lesquels choisir. Ces artistes
        prendront des décisions différentes à chaque étape du processus pour
        créer votre magie. vous pouvez voir quelques exemples ci-dessus; pour
        les débutants, nous suggérons
      </>
    ),
    size_top_text: "Quelle taille souhaitez-vous pour vos oeuvres",
    size_bottom_text:
      "Vous pouvez sélectionner de 64px à 2048px pour la hauteur ou la largeur.",
    high_top_text: "Aller GRAND?",
    high_bottom_text:
      "les artistes travaillent mieux autour de 512px et si vous dépassez ce point, cela devrait être fait dans une seule direction ou avec l'aide de ce paramètre de haute qualité qui divisera le travail en 512x512 travaux et les fusionnera.",
    high_input_label: "Haute qualité",
    steps_top_text: "Pas",
    steps_bottom_text:
      "chaque étape permet à l'artiste d'améliorer votre image, environ 20 étapes vous permettront d'obtenir une image de base, une cinquantaine serait préférable pour une meilleure qualité.",
    steps_input_label: "Étapes d'échantillonnage",
    artist_freedom_top_text: "Liberté de l'artiste",
    artist_freedom_bottom_text: (
      <>
        ici, vous pouvez choisir le poids de votre contribution à la pièce. Un
        réglage de base d'environ 7 donne des résultats qui représentent à la
        fois vos idées et une certaine liberté d'expression. 1 ne contiendra
        qu'un soupçon de votre contribution et 30 contiendra très probablement
        les idées.
        <br></br> Suggéré : 5-15
      </>
    ),
    artist_freedom_input_label: "CFG scale",
    //create page/quality
    starting_number: "Numéro de départ",
    starting_number_bottom_text:
      "Vous pouvez éventuellement spécifier un point de départ pour votre art. Cela permettra des ajustements futurs.",
    draft_quality: "Qualité brouillon",
    draft_quality_bottom_text: "Le plus rentable",
    basic_quality: "Qualité de base",
    basic_quality_bottom_text: "Plus raffiné",
    standard_quality: "Qualité standard",
    standard_quality_bottom_text: "Doubler la taille",
    premium_quality: "Qualité supérieure",
    premium_quality_bottom_text: "Plus raffiné",
    supreme_quality: "Qualité suprême",
    supreme_quality_bottom_text: "Énorme",
    custom: "Personnalisé",
    custom_bottom_text: "Devenir fou",
    //home page
    home_page_first_slider_top_text:
      "Créez quelque chose d'incroyable en quelques minutes",
    home_page_first_slider_bottom_text: "La machine fait tout le travail",

    home_page_second_slider_top_text: "Créez des images presque instantanément",
    home_page_second_slider_middle_text:
      "vous fournissez les concepts de l'art et avez accès à des options avancées supplémentaires si vous voulez devenir fou",
    home_page_second_slider_bottom_text:
      "Vous pouvez télécharger et disposer de tous les droits d'utilisation de votre œuvre, ainsi que de toutes les informations nécessaires pour recréer ou remixer votre création. ",

    join: "Rejoindre",
    join_top_text: "Rejoignez et créez des images étonnantes",
    become: (
      <>
        Devenez un utilisateur d'Artfactory <br></br> maintenant
      </>
    ),
    register_text:
      "Enregistrez un compte avec nous et obtenez de superbes photos",
    go_to_create_page: "Aller à Créer une page",
    go_to_create_page_text:
      "Accédez à la page de création et créez vos propres images",
    write_your_magic: "Écrivez votre magie",
    write_your_magic_text: "Écrivez votre magie et la machine fera le reste",
    wait_for_the_result: "Attendez le résultat",
    wait_for_the_result_text:
      "Attendez les résultats et profitez de vos nouvelles images",

    FAQ_short_title: "FAQ",
    FAQ_title: "Questions fréquemment posées",
    FAQ: [
      {
        id: 1,
        title: "QU'EST-CE QU'UN ARTISTE?",
        content: (
          <>
            <p>Chaque artiste est un système de génération d'images.</p>
            <p>Il combine des idées précédentes, notamment:</p>
            <ul>
              <li>
                ● Perception Engines qui utilise l'augmentation d'image et
                optimise de manière itérative les images par rapport à un
                ensemble de classificateurs
              </li>
              <br></br>
              <li>
                ● Imagerie GAN guidée par CLIP de Ryan Murdoch et Katherine
                Crowson ainsi que des modifications telles que CLIPDraw de Kevin
                Frans
              </li>
              <br></br>
              <li>
                ● Moyens utiles de naviguer dans l'espace latent à partir des
                réseaux génératifs d'échantillonnage
              </li>
              <br></br>
            </ul>
          </>
        ),
        show: "true",
      },
      {
        id: 2,
        title: "POURQUOI LES IMAGES PLUS GRANDES COÛTENT-ELLES PLUS GRANDES ??",
        content: (
          <>
            <p>
              Chaque image est générée image par image et cela consomme des
              ressources considérables.
            </p>
            <ul>
              <li>● Le brouillon ne prend que quelques secondes</li>
              <li>● Suprême peut prendre 30 minutes</li>
              <br></br>
            </ul>
            <p>
              Pendant ce temps, nous avons le dernier matériel de réseau
              neuronal totalement consommé par vos œuvres d'art.
            </p>
            <p>
              Nous pouvons évoluer en achetant plus de matériel et en traitant
              davantage en parallèle.
            </p>
          </>
        ),
      },
      {
        id: 3,
        title: "POURQUOI JE NE PEUX PAS AVOIR UNE PLUS GRANDE IMAGE?",
        content:
          "Nous travaillons sur une solution pour faire des images encore plus grandes, en ce moment nous sommes limités par la quantité de mémoire que nous pouvons avoir chez un seul artiste. Nous espérons ajouter l'ajout de super-échantillonnage pour augmenter considérablement la taille.",
      },
    ],
    //unique ones

    home_data1: "FOURNIR L'IDÉE",
    home_data2:
      "Vous fournissez les concepts de l'art et avez accès à des options avancées supplémentaires si vous voulez devenir fou",
    home_data3: "Payer pour l'art",
    home_data4:
      "Vous pourrez payer l'art en utilisant les options de paiement fournies.",
    home_data5: "L'ARTISTE SE MET AU TRAVAIL…",
    home_data6:
      "Vous obtiendrez les résultats du travail de l'artiste sous la page de la galerie.",
    home_data7: "RAVIEZ-VOUS DE VOTRE CRÉATION",
    home_data8:
      "Vous pouvez télécharger et disposer de tous les droits d'utilisation de votre œuvre, ainsi que de toutes les informations nécessaires pour recréer ou remixer votre création.",
    home_data9: "Images générées",
    home_data10: (
      <>
        Vous fournissez les concepts pour l'art et avez accès à des options
        avancées supplémentaires si vous voulez devenir fou.
        <br />
        Les moteurs de perception sont capables de construire des objets
        physiques représentatifs et sont principalement alimentés par la
        perception computationnelle.
        <br />
        VQGAN+CLIP est une architecture de réseau neuronal qui s'appuie sur
        l'architecture CLIP révolutionnaire publiée par OpenAI en janvier 2021.
        L'espace latent peut être échantillonné pour générer des valeurs de
        données observables. <br />
        Les représentations latentes apprises permettent souvent aussi des
        opérations sémantiques avec l'arithmétique de l'espace vectoriel.
      </>
    ),
    hot_collection: "Collection chaude",
    quality: "Qualité",
    settings: "Paramètres",
    name_email_phone: "Nom, email, téléphone",
    location: "Emplacement",
    size: "Taille",
    item_details: "Détails de l'article",
    home: "Accueil",
  },

  //azerbaijani
  az: {
    //buttons
    high_quality: "Yüksək keyfiyyət",
    login: "Daxil ol",
    register: "Qeydiyyatdan keç",
    logout: "Çıxış",
    profile: "Profil",
    gallery: "qalereya",
    create: "Yaratmaq",
    inspiration: "İnspirasion",
    make_art: "Sənət etmək",
    my_gallery: "Mənim Qalereyam",
    generate_picture: "Şəkil yaradın",
    submit: "təqdim",
    refresh: "təzələmək",
    download: "Yüklə",
    go_to_customize: "Fərdiləşdirməyə keçin",
    save: "Yadda saxla",
    delete: "Sil",
    go_to_create: "Yarat-a keçin",
    terms_and_conditions: "Şərtlər və Qaydalar",
    privacy_policy: "Gizlilik Siyasəti",
    dimensions: "Ölçülər",
    Order_ID: "Sifariş ID",
    Art_Name: "Sənət adı",
    Payment_Status: "Ödəniş statusu",
    unpaid: "Ödənilməyib",

    pop_title: "Sizin üçün razılaşmırsınız?",
        pop_text: "Bizimlə problemi həll edin.",
        pop_button_text: "Buraya klik edin!",

        pop_qna_close_text: "Bağla",
        pop_something_went_wrong: "Apsə, bir şey səhv getdi!",
        qna: {
            base: {
                question: "Nə baş verdi?",
                answers: {
                    speed: {
                        text: "Sürət",
                        redirect: "speed_whats_wrong"
                    },
                    payment: {
                        text: "Ödəniş"
                    },
                    quality: {
                        text: "Keyfiyyət"
                    }
                }
            },

            speed_whats_wrong: {
                question: "Sürətlə nə problem var?",
                answers: {
                    speed_too_slow: {
                        text: "Çox yavaş",
                    },
                    speed_not_loading: {
                        text: "Sonunda dayanıb.",
                    },
                    speed_stuck: {
                        text: "İşləmir.",
                    },
                },
            },

            payment_whats_wrong: {
                question: "Ödənişlə nə problem var?",
                answers: {
                    payment_still_pending: {
                        text: "Artıq ödənib, lakin hələ gözləmədə...",
                    },
                    payment_charged_twice: {
                        text: "İki dəfə debit edildim.",
                    },
                }
            },
            payment_still_pending: {
                question: "Yenidən yaradınmı cəhd edin?",
                answers: {
                    payment_still_pending_regen: {
                        text: "Bəli.",
                    },
                    payment_still_pending_refund: {
                        text: "Xeyr, mən geri ödəniş istəyirəm.",
                    },
                },
            },

            quality_whats_wrong: {
                question: "Keyfiyyətlə nə problem var?",
                answers: {
                    quality_bad: {
                        text: "Şəklin keyfiyyəti pisdir.",
                    },
                    payment_charged_twice: {
                        text: "Şəkil tamamilə yaradılmır.",
                    },
                },
            },
            quality_bad: {
                question: "Yenidən yaradınmı cəhd edin?",
                answers: {
                    quality_bad_regen: {
                        text: "Bəli.",
                    },
                    quality_bad_refund: {
                        text: "Xeyr, mən geri ödəniş istəyirəm.",
                    },
                },
            },

            refund: {
                question: "Bu işləmədiyini eşitdiyimə üzr istəyirəm.",
                answers: {
                    refund_refund: {
                        text: "Geri ödəniş istəyirəm.",
                    },
                    refund_free_token: {
                        text: "Pulsuz token istəyirəm."
                    }
                }
            }
        },

        pop_refund_title: "Bu qərarı verən şəkil hansıdır?",
        pop_refund_button: "Qəbul et və göndər",
        pop_refund_button_disabled: "Ən azı birini seçin!",

        pop_redirect_close: "Anladım. Umarım siz bunu həll edə bilərsiniz.",
        pop_redirect_redirecting: "Yönləndirilir...",


    //profile
    first_name: "Ad",
    last_name: "Soyad",
    email: "E-poçt",
    password: "Şifrə",
    confirm_password: "Şifrəni təsdiqləyin",
    phone: "telefon",
    address: "Ünvan",
    city: "Şəhər",
    state: "Dövlət",
    zip: "Zip",
    country: "Ölkə",
    county: "Şəhər",

    //inspiration
    steps: "Adımlar",
    seed: "Tohum",
    width: "En",
    height: "Hündürlük",
    artist: "Rəssam",
    template: "Şablon",
    input: "Giriş",
    price: "Qiymət",
    create_amazing_pictures: "Gözəl Şəkillər Yaradın",

    //footer action div
    footer_action_div_top_text: "Yeni Sənətinizi Yaradın",
    footer_action_div_bottom_text: "Möhtəşəm bir şey yaradın",
    //create page
    positive_top_text: "Şedevriniz haqqında bizə məlumat verin",
    positive_bottom_text: (
      <>
        Rəssamın şəklinizi yaratması üçün bir neçə mətn daxil edin,
        ideyalarınızı istədiyiniz nəticə üçün başlıq kimi düşünsəniz, bu, daha
        yaxşı olar.<br></br>
      </>
    ),
    avoid_top_text: "Qaçmaq istədiyiniz bir şey varmı",
    avoid_bottom_text: (
      <>
        Şəkilinizdə görmək istəmədiyiniz şeyləri buraya əlavə edə bilərsiniz.
        yuxarıdakı kimi, qarşısını almaq üçün maddələrin başlıqlarından istifadə
        etmək yaxşıdır.
      </>
    ),
    artist_top_text: "Sənətkarın",
    artist_bottom_text: (
      <>
        Hazırda seçmək üçün 3 rəssam var. Bu rəssamlar sehrinizi yaratmaq üçün
        hər addımda fərqli qərarlar verəcəklər. yuxarıda bəzi nümunələrə baxa
        bilərsiniz; yeni başlayanlar üçün rəssam 1 təklif edirik
      </>
    ),
    size_top_text: "Əsərlərinizin hansı ölçüdə olmasını istərdiniz",
    size_bottom_text:
      "Hündürlüyü və ya eni üçün 64px ilə 2048px arasında seçim edə bilərsiniz.",
    high_top_text: "BÖYÜK gedirsiniz?",
    high_bottom_text:
      "Rəssamlar ən yaxşı şəkildə 512px ətrafında işləyirlər və bu nöqtəni keçsəniz, bu, bir istiqamətdə və ya işi 512x512 işlərə böləcək və onları birləşdirəcək bu yüksək keyfiyyətli parametrin köməyi ilə edilməlidir.",
    high_input_label: "Yüksək keyfiyyət",
    steps_top_text: "addımlar",
    steps_bottom_text:
      "Hər bir addım rəssama təsvirinizi təkmilləşdirməyə imkan verir, təxminən 20 addım sizə əsas görüntü əldə edəcək, daha yüksək keyfiyyət üçün 50 və ya daha çox addım daha yaxşı olardı.",
    steps_input_label: "Nümunə alma addımları",
    artist_freedom_top_text: "Rəssam Azadlıq",
    artist_freedom_bottom_text: (
      <>
        Burada siz parçaya daxil etdiyiniz çəkiyi seçə bilərsiniz. Təxminən 7
        əsas parametr həm fikirlərinizi təmsil edən, həm də müəyyən ifadə
        azadlığına malik nəticələr verir. 1 yalnız girişinizin bir ipucunu
        ehtiva edəcək və 30-u çox güman ki, ideyaları yandıracaq.
        <br></br> Təklif olunan: 5-15
      </>
    ),
    artist_freedom_input_label: "CFG şkalası",
    //create page/quality
    starting_number: "Başlanğıc nömrəsi",
    starting_number_bottom_text: "Könüllü",
    draft_quality: "Qaralama keyfiyyəti",
    draft_quality_bottom_text: "Ən sərfəli",
    basic_quality: "Əsas keyfiyyət",
    basic_quality_bottom_text: "Daha zərif",
    standard_quality: "Standart keyfiyyət",
    standard_quality_bottom_text: "Ölçüsü ikiqat",
    premium_quality: "Yüksək keyfiyyət",
    premium_quality_bottom_text: "Daha zərif",
    supreme_quality: "Yüksək keyfiyyət",
    supreme_quality_bottom_text: "böyük",
    custom: "Xüsusi",
    custom_bottom_text: "Dəli ol",
    //home page
    home_page_first_slider_top_text:
      "Bir neçə dəqiqə ərzində heyrətamiz bir şey yaradın",
    home_page_first_slider_bottom_text: "Maşın bütün işləri görür",
    home_page_second_slider_top_text: "Demək olar ki, dərhal şəkillər yaradın",
    home_page_second_slider_middle_text:
      "siz sənət üçün konseptlər təqdim edirsiniz və dəli olmaq istəyirsinizsə, əlavə təkmil seçimlərə çıxış əldə edirsiniz",
    home_page_second_slider_bottom_text:
      "Siz yaradıcılığınızı yenidən yaratmaq və ya remiks etmək üçün lazım olan bütün məlumatlarla yanaşı, sənət əsərinizi yükləyə və tam istifadə hüquqlarına malik ola bilərsiniz.",
    idea: "İdeya",
    write: "yaz",
    wait: "Gözləmək",
    art: "İncəsənət",
    join: "Qoşulun",
    join_top_text: "Qoşulun və heyrətamiz şəkillər yaradın",
    //become:<>Become a Artfactory <br></br> User Now </>,
    register_text: "Bizimlə qeydiyyatdan keçin və gözəl şəkillər əldə edin",
    go_to_create_page: "Səhifə Yarat-a keçin",
    go_to_create_page_text: "Səhifəni yaradın və öz şəkillərinizi yaradın",
    write_your_magic: "Sehrinizi yazın",
    write_your_magic_text: "Sehrinizi yazın, qalanını maşın edəcək",
    wait_for_the_result: "Nəticəni gözləyin",
    wait_for_the_result_text:
      "Nəticələri gözləyin və yeni şəkillərinizdən həzz alın",

    FAQ_short_title: "Tez-tez soruşulan suallar",
    FAQ_title: "Tez-tez soruşulan suallar",
    FAQ: [
      {
        id: 1,
        title: "ARTIST NƏDİR?",
        content: (
          <>
            <p>Hər bir rəssam bir obraz yaratma sistemidir.</p>
            <p>O, əvvəlki fikirləri birləşdirir, o cümlədən:</p>
            <ul>
              <li>
                ● Şəkil artımından istifadə edən və şəkilləri iterativ olaraq
                optimallaşdıran Perception Engines təsnifatçılar ansamblı
              </li>
              <br></br>
              <li>
                ● CLIP, Ryan Murdoch və Katherine Crowson-dan GAN görüntüləri,
                eləcə də dəyişikliklər kimi Kevin Fransdan ClipDraw
              </li>
              <br></br>
              <li>
                ● Sampling Generative Networks-dən gizli məkanda naviqasiyanın
                faydalı yolları
              </li>
              <br></br>
            </ul>
          </>
        ),
        show: "true",
      },
      {
        id: 2,
        title: "BÖYÜK ŞƏKİLLƏR ÜÇÜN NİYƏ DAHA XİÇƏTLİDİR?",
        content: (
          <>
            <p>
              Hər bir şəkil kadr-kadr yaradılır və bu, xeyli resurslar sərf
              edir.
            </p>
            <ul>
              <li>● Qaralama yalnız bir neçə saniyə çəkir</li>
              <li>● Supreme 30 dəqiqə çəkə bilər</li>
              <br></br>
            </ul>
            <p>
              Bu müddət ərzində sənət əsəriniz tərəfindən tamamilə istehlak
              edilən ən son neyron şəbəkə aparatımız var.
            </p>
            <p>
              Biz daha çox aparat almaqla miqyasını artıra bilirik və paralel
              olaraq daha çox emal edirik.
            </p>
          </>
        ),
      },
      {
        id: 3,
        title: "NİYƏ DAHA BÖYÜK ŞƏKİLƏ OLA BİLMİRƏM?",
        content:
          "Biz daha böyük şəkillər yaratmaq üçün bir həll üzərində işləyirik, hazırda tək bir sənətçidə ola biləcəyimiz yaddaş miqdarı ilə məhdudlaşırıq. Ümid edirik ki, ölçüsünü xeyli artırmaq üçün super nümunə əlavəsini əlavə edəcəyik.",
      },
    ],

    //unique ones

    home_data1: "İDEYANI TƏQDİM EDİN",
    home_data2:
      "Siz sənət üçün konseptlər təqdim edirsiniz və dəli olmaq istəyirsinizsə, əlavə təkmil seçimlərə çıxış əldə edirsiniz",
    home_data3: "Sənət üçün ödə",
    home_data4:
      "Siz təqdim olunan ödəniş seçimlərindən istifadə edərək sənət üçün ödəniş edə biləcəksiniz.",
    home_data5: "ARTIST İŞƏ GƏLİR...",
    home_data6:
      "Sənətkarın işinin nəticələrini qalereya səhifəsindən əldə edəcəksiniz.",
    home_data7: "YARADILIŞINIZDAN QEYD OLUN",
    home_data8:
      "Siz yaradıcılığınızı yenidən yaratmaq və ya remiks etmək üçün lazım olan bütün məlumatlarla yanaşı, sənət əsərinizi yükləyə və tam istifadə hüquqlarına malik ola bilərsiniz.",
    home_data9: "Yaradılmış Şəkillər",
    home_data10: (
      <>
        Siz sənət üçün konseptlər təqdim edirsiniz və dəli olmaq istəyirsinizsə,
        əlavə təkmil seçimlərə giriş əldə edirsiniz.
        <br />
        Qavrama Mühərrikləri təsviri fiziki obyektlər qura bilir və ilk növbədə
        hesablama qavrayışı ilə təchiz edilir.
        <br />
        VQGAN+CLIP 2021-ci ilin yanvarında OpenAI tərəfindən nəşr olunan
        inqilabi CLIP arxitekturasına əsaslanan neyron şəbəkə arxitekturasıdır.
        Müşahidə edilə bilən məlumat dəyərləri yaratmaq üçün gizli məkandan
        nümunə götürülə bilər. <br />
        Öyrənilmiş latent təsvirlər tez-tez vektor məkanı arifmetikası ilə
        semantik əməliyyatlara imkan verir.
      </>
    ),
    hot_collection: "Qaynar Kolleksiya",
    quality: "Keyfiyyət",
    settings: "Parametrlər",
    name_email_phone: "Ad və Soyad, E-poçt və Telefon",
    location: "Yerləşdiyiniz Yer",
    size: "Ölçü",
    item_details: "Məhsulun Təsviri",
    home: "Ev",
  },
};
