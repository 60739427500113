import React from 'react';
import PropTypes from 'prop-types';
// import icon from '../../assets/images/icon.png';

import { Navigation , Autoplay   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss'

RoadMap.propTypes = {
    data: PropTypes.array,
};

function RoadMap(props) {
    const data = [
        {
            id: 1,
            title: 'First step is Choose your quality Settings',
            class: 'left',
            list : [
                {
                    id: 1,
                    text: "Draft",
                },
                {
                    id: 2,
                    text: 'Baisc',
                },
                {
                    id: 3,
                    text: 'Standard',
                },
                {
                    id: 4,
                    text: 'Premium',
                },
                {
                    id: 5,
                    text: 'Supreme',
                },
                {
                    id: 6,
                    text: 'Custom',
                },
            ]

        },
        {
            id: 2,
            title: 'Quality description',
            class: 'right',
            list : [
                {
                    id: 1,
                    text: "Draft is the most cost-effective way to get your picture, --Size:512x512,",
                },
                {
                    id: 2,
                    text: 'Basic is the more refined way to get your picture, Size:512x512',
                },
                {
                    id: 3,
                    text: 'Standard contains 30 steps to make your picture look more realistic, Size:1024x1024',
                },
                {
                    id: 4,
                    text: 'Premium uses 40 steps to make it more better, Size:1024x1024',
                },
                {
                    id: 5,
                    text: 'Supreme uses 50 steps and operate the maximum quality, Size:1600x1600 ',
                },
                {
                    id: 6,
                    text: 'Custom. You can choose your own quality settings.',
                },
            ]

        },
        {
            id: 3,
            title: 'Seed',
            class: 'left',
            list : [
                {
                    id: 1,
                    text: 'Originally the seed was a random number, the same seed will always give the same result. (optional)',
                },
            ]

        },
        {
            id: 4,
            title: 'Write your idea',
            class: 'right',
            list : [
                {
                    id: 1,
                    text: 'Enter some text for the artist to create your image, it works best if you think of your ideas as captions for the result you would like.',
                },

            ]

        },
        {
            id: 5,
            title: 'If you want to avoid something',
            class: 'left',
            list : [
                {
                    id: 1,
                    text: 'The next step is optional.You can add some text to avoid, this will help the artist to avoid certain things in the image.'
                },

            ]

        },
        {
            id: 6,
            title: 'Choose the artist',
            class: 'right',
            list : [
                {
                    id: 1,
                    text: 'There are currently 3 artists to choose from. These artists will make different decisions each step of the way to create your magic. you can see some examples above; for beginners we suggest artist1',
                },

            ]

        },





    ]


    return (
        <section className="tf-section section-roadmap3 section-bg-1" style={{paddingTop:'0'}} >
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-md-12 wow fadeInUp">
                            <div className="tf-roadmap">
                            <Swiper
                               
                                breakpoints={{
                                    0: {
                                        spaceBetween:0,
                                        slidesPerView: 1,
                                        },
                                    500: {
                                        spaceBetween:30,
                                        slidesPerView: 2,
                                        },
                                    768: {
                                        spaceBetween:30,
                                        slidesPerView: 3,
                                    },
                                    991: {
                                        spaceBetween:55,
                                        slidesPerView: 4,
                                    },
                                }}
                                centeredSlides={true}
                                loop={true}
                                navigation={true}
                                modules={[Autoplay, Navigation]}
                                className="swiper sl-roadmap"
                            >

                                {
                                    data.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <div className="roadmap-box">
                                                <div className="shape-circle">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="176" viewBox="0 0 60 176" fill="none">
                                                        <path opacity="0.7" d="M30 176L30 40" stroke="var(--primary-color13)" strokeWidth="2" strokeDasharray="6 6"/>
                                                        <circle cx="30" cy="30" r="30" fill="#21E786" className="fill-1" fillOpacity="0.2"/>
                                                        <circle cx="30" cy="30" r="15" fill="#21E786" className="fill-2"/>
                                                        </svg>
                                                </div>
                                                <div className="content ">
                                                    <h4 className="title">{idx.title}</h4>
                                                    <ul className="list-infor">
                                                        {
                                                            idx.list.map(idx => (
                                                                <li key={idx.id}>
                                                                    <div className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">

                                                                            <g filter="url(#filter0_f_3265_431)">
                                                                                <path d="M12 82.7487V12H82" stroke="url(#paint0_linear_3265_431)" strokeWidth="3"/>
                                                                            </g>
                                                                            <path d="M12 82.7487V12H82" stroke="url(#paint1_linear_3265_431)" strokeWidth="3"/>
                                                                            <defs>
                                                                                <filter id="filter0_f_3265_431" x="0.5" y="0.5" width="91.5" height="92.2485" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                                                                    <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_3265_431"/>
                                                                                </filter>
                                                                                <linearGradient id="paint0_linear_3265_431" x1="12" y1="12.6612" x2="43.6514" y2="46.8388" gradientUnits="userSpaceOnUse">
                                                                                    <stop stopColor="#21E786"/>
                                                                                    <stop offset="1" stopColor="#21E786" stopOpacity="0"/>
                                                                                </linearGradient>
                                                                                <linearGradient id="paint1_linear_3265_431" x1="12" y1="12.6612" x2="43.6514" y2="46.8388" gradientUnits="userSpaceOnUse">
                                                                                    <stop stopColor="#21E786"/>
                                                                                    <stop offset="1" stopColor="#21E786" stopOpacity="0"/>
                                                                                </linearGradient>
                                                                            </defs>
                                                                        </svg>

                                                                    </div>
                                                                    {idx.text}
                                                                </li>
                                                            ))
                                                        }
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
   

                            </Swiper>

                            </div>
                        </div>
                    </div>
                 </div>
            </section>
    );
}

export default RoadMap;