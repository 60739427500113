import React, {useContext, useEffect} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {EmptyJobCheck} from "../Helper/functions";
import {DataContext} from "../Helper/context";


const Thanks = (props) => {
    const navigate = useNavigate();
    const {user} = useContext(DataContext)
    const params = window?.location?.search

    const paymentStatusCheck = async (jobId) => {
        let reqBody = {
            "merchant_id": "painterai",
            "job_id": jobId
        }
        await axios.post('https://api.paintr.ai/payment', reqBody, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        },)
            .then((res) => {
                if (res?.data) {
                    if(res?.data.status !== 'success'){
                        navigate('/gallery')
                    }
                    if (res.data.status === "success") {
                        startJob(jobId)
                        return null;
                    }
                }else{
                    navigate('/gallery')
                }
            })
            .catch((error) => {
                navigate('/gallery')
            });
    }

    const startJob = async (id) => {
        await axios.get(`https://api.paintr.ai/start/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                navigate('/gallery')
            })
            .catch((error) => {
                navigate('/gallery')
            });
    }
    useEffect(() => {
        if (params) {
            let jobId = params.split("=")[1]
            console.log(jobId)
            paymentStatusCheck(jobId).then(()=>{
                EmptyJobCheck(user)
            })
        }else {
            navigate('/gallery')
        }
    }, [])

    return (
        <></>
    );
}

export default Thanks;