import React, {useState, useContext, useEffect} from "react";
import {Navigate, useNavigate} from 'react-router-dom';
import PageTitle from "../components/pagetitle/PageTitle";
import {Link} from "react-router-dom";
import { Languages } from "../Helper/languages";
import {DataContext} from "../Helper/context";
import {GetAllJobs} from "../Helper/functions";
import Footer2 from "../components/footer/Footer2";
import '../components/collection/styles.scss'
function Gallery(props) {
    const {user, language} = useContext(DataContext);
    const navigate = useNavigate();
    const [pending, setPending] = useState(false);
    const [imagesFromLocalStorage, setImagesFromLocalStorage] = useState(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])

    useEffect(() => {
        GetAllJobs( user).then(res => {
            setImagesFromLocalStorage(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
        })
    }, []);

    useEffect(() => {
        if (Array.isArray(imagesFromLocalStorage) && imagesFromLocalStorage.length > 0) {
            let pendingJobs = imagesFromLocalStorage.filter(job => job.status === "running")
            if (pendingJobs.length > 0) {
                setPending(true)
                setTimeout(() => {
                    GetAllJobs( user).then(res => {
                        setImagesFromLocalStorage(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
                    })
                }, 2000)
            } else {
                setPending(false)
            }
        }
    }, [imagesFromLocalStorage]);

    const RefreshGallery = () => {
        ( user).then(res => {
            setImagesFromLocalStorage(localStorage.getItem('jobs') ? JSON.parse(localStorage.getItem('jobs')) : [])
        })
    }

    if (!user) {
        return <Navigate to='/signin'/>
    }

    return (
        <div className="page-collection">
            <PageTitle title={Languages[language].my_gallery}/>

            <section className="tf-collection-inner">
                <div className="tf-container">
                    <div
                        className="row"
                        style={{display: "flex", justifyContent: "center"}}
                    >
                        <div className="col-lg-9 col-md-8 ">
                            <div className="top-option">
                                <h2 className="heading">{Languages[language].gallery}</h2>
                            </div>
                            <ul className="filter-content">
                                <li>
                                    <Link onClick={RefreshGallery}>{Languages[language].refresh}</Link>
                                </li>
                                {
                                    pending && <li>
                                        <Link onClick={RefreshGallery}>Processing
                                            <div className="lds-ring">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </Link>

                                    </li>
                                }

                            </ul>
                            <div className="row">
                                {imagesFromLocalStorage.sort((a, b) => b.id - a.id).map((idx) => {
                                    const {status, id, config} = idx

                                    if (status !== 'finished') return null

                                    return <div
                                        onClick={() => navigate(`/view?${id}`)}
                                        key={id}
                                        className="col-lg-4 col-md-6 col-sm-6 col-12 "
                                    >
                                        <div className="tf-product">
                                            <div className="image">
                                                <img src={`https://api.paintr.ai/image/${id}`} alt="Binabox"/>
                                            </div>
                                            <h6 className="name">
                                                <Link>{
                                                    config.prompt.length > 20 ? config.prompt.substring(0, 20) + '...' : config.prompt
                                                }</Link>
                                            </h6>
                                        </div>
                                    </div>
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer2/>
        </div>
    );
}

export default Gallery;
